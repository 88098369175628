.home-banner{
    background: #c2e59c;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #64b3f4, #c2e59c);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #64b3f4, #c2e59c); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
padding: 30px;
border-radius: 20px;
margin-top: 20px;

}

.home-banner h1{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}