.players{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    
}

.player-bg{
  padding-top: 20px;
  background-image: url("https://i.ibb.co/Y29c70m/Field.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  /* Set the background image as fixed */
  background-attachment: fixed;
}
.player{
  background-color: #64b3f423;
  
}

.playerName p{

  color: rgb(255, 255, 255) !important;
  font-size: 12px !important;
}
.playerName{
  background-color: #0f64aa;
  display: flex;
  justify-content: center;
  border-radius: 20px;
}

.playerPic img{
  width: 100px;
  height: 100px;
  border-radius: 20px;
}

.players-banner{
  background: #c2e59c;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #64b3f4, #c2e59c);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #64b3f4, #c2e59c); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
padding: 30px;
border-radius: 20px;
margin-top: 20px;
display: flex;
justify-content: center;

}

.players-banner h2{
  color: white;
  font-family:Georgia, 'Times New Roman', Times, serif;
}

@media only screen and (max-width: 600px) {

  .players img{
    width: 100px !important;
    height: 100px !important;
    
  }
    .players {
      margin: -20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      
    }
  }
  .loading-section{
    display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  }
  /* Add these styles to your Players.css file */

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-button {
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  color: #333;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.page-button:hover {
  background-color: #333;
  color: #fff;
}

.page-button.active {
  background-color: #007bff;
  color: #fff;
}

li{
  list-style: none;
}