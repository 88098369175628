
.join-button{
    list-style-type: none;
}
.join-page {
    background-image: url("https://i.ibb.co/Y29c70m/Field.jpg");
    height: 100vh;
  
    /* Make the background image cover the entire container */
    background-size: cover;
  
    /* Center the background image horizontally and vertically */
    background-position: center;
    background-repeat: no-repeat;
  }
.join{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

}
.button-border{
    margin-bottom: 30px;
    background-color: black;
    border: 2px solid rgb(255, 255, 255);
    padding-left: 40px;
    padding-right: 40px;
}
.button-border:hover{
    color: bisque !important;
}