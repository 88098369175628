.status{
    display: flex;
    justify-content: center;
}
Button{
    margin-left: 10px;
}
.orders{
    border-radius: 30px;
    margin-top: 15px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}