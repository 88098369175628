.logo img{
    width: 50px;
}
.log-out{
    width: 30px;
}
.profile-icon{
    display: flex;
    justify-content: center;
}
.login-btn{
    border: 1px solid blue;
    padding: 10px;
    border-radius: 30px;
}
.main-header{
    margin-top: -20px;
    position: sticky;
    top: 0px;
    z-index: 100;
    background-color: rgb(248, 248, 248);
}
