.videos-banner{
    background: #c2e59c;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #64b3f4, #c2e59c);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #64b3f4, #c2e59c); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
padding: 30px;
border-radius: 20px;
margin-top: 20px;
display: flex;
justify-content: center;

}

.videos-banner h2{
    color: white;
    font-family:Georgia, 'Times New Roman', Times, serif;
}


.video{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 30px;
}
.video h4{
    font-size: 13px;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

.banner{
    width: 100%;
    border-radius: 30px;
}
.video-title{
    display: flex;
    justify-content: center;
}
