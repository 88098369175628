.academis{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
.academyName{
  color: black;
  font-size: 12px;
}


.academy-cards{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}


.academy-cards-all-cards{
  display: flex;
  justify-content: center;
}
.academy-cards img{
  margin: 10px;
  display: flex;
  justify-content: center;
  width: 130px;
  height: 110px;
  border-radius: 15px;
}
.academy-cards-single{
  margin: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 15px;
}
.academy-cards-single p{
  color:rgb(87, 87, 87);
  font-size: 12px;
}
.academy{
  display: flex;
  justify-content: center;
}
.text{
  display: flex;
  justify-content: center;
}
h1{
  color: white;
  font-weight: 700;
}
h3{
  color: white;
  font-weight: 700;
 
}
.title-content{
  display: flex;
  justify-content: space-between;
  background: #c2e59c;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #64b3f4, #c2e59c);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #64b3f4, #c2e59c); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
padding: 5px;
}
.title{
  color: #ffffff;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif
}

@media only screen and (max-width: 600px) {
    .academis {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
  }