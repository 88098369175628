/* AcademyDetails.css */
.academy-profile img {
    border-radius: 50%;
    border: 5px solid skyblue;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .practice-time,
  .phone-number,
  .address {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 10px;
  }
  
  .phone-number p,
  .address p,
  .practice-time p {
    font-size: 16px;
    margin-left: 10px;
    color: #333;
  }
  
  .practice-time img,
  .phone-number img,
  .address img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  .academy-card-one {
    margin: 10px;
    border-radius: 10px;
    background: linear-gradient(90deg, rgba(131, 58, 180, 1) 0%, rgba(156, 29, 253, 1) 38%, rgba(252, 176, 69, 1) 100%);
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .academy-card-one h3 {
    color: white;
    margin-top: 10px;
  }
  
  .academy-card-one img {
    border-radius: 30px;
    border: 5px solid wheat;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .practice-date {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 20px;
    background-color: #af37ff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .practice-date h2,
  .practice-date p {
    color: white;
    font-weight: 400;
  }
  
  .center-position {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .academy-name h1,
  .about h1 {
    color: #3e3ef3;
    font-weight: 600;
    text-align: center;
  }
  
  .about-academy p {
    color: #181818;
    line-height: 1.6;
    text-align: center;
  }
  
  .academy-photos img {
    width: 100%;
    margin-bottom: 10px;
    border: 2px solid blue;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  