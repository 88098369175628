.player-name{
    display: flex;
    justify-content: center;
}
.player-name p{
 color: rgb(134, 134, 134);
 display: flex;
 justify-content: center;
 margin-top: -5px;
}
.about{
    color: rgb(44, 44, 44);
    font-size: 15px;
}
.player-name h2{
font-size: 15px;
}

.player-profile-img{
  display: flex !important;
  justify-content: center !important;
}

#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  #customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  #customers tr:nth-child(even){background-color: #f2f2f2;}
  
  #customers tr:hover {background-color: #ddd;}
  
  #customers th {
    font-size: 10px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #04AA6D;
    color: white;
  }

  td{
    font-size: 10px;
  }