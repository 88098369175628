.cricket-training{
    background: #c2e59c;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #64b3f4, #c2e59c);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #64b3f4, #c2e59c); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
padding: 30px;
border-radius: 20px;
margin-top: 20px;
margin-bottom: 10px;

}

.training-page{
  background-image: url("https://i.ibb.co/Y29c70m/Field.jpg");

  /* Make the background image cover the entire container */


  /* Center the background image horizontally and vertically */
  background-position: center;
  background-repeat: no-repeat;
}


.cricket-training h2{
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

}

.training-cards{
  margin-top: 70px;
  margin-bottom: 70px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .single-card{
    display: flex;
    align-items: center;
    border-radius: 30px;
    margin: 8px;
    padding: 5px;
    background-color: #dcfff29f;
  }
  .card-two{
    display: flex;
    align-items: center;
    border-radius: 30px;
    margin: 8px;
    padding: 5px;
    background-color: #04AA6D;
  }
  .card-three{
    display: flex;
    align-items: center;
    border-radius: 30px;
    margin: 8px;
    padding: 5px;
    background-color: #04AA6D;
  }
  .card-four{
    display: flex;
    align-items: center;
    border-radius: 30px;
    margin: 8px;
    padding: 5px;
    background-color: #04AA6D;
  }
 
  .all-cards{
    display: flex;
    justify-content: center;
  }
  .training-cards img{
    width: 50px;
    height: 40px;
  }
  .single-training-card{
    
    background-color: rgba(240, 248, 255, 0.24);
    margin: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: rgb(252, 252, 253) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 15px;
  }
  .single-training-card p{
    color: rgb(255, 255, 255);
    font-size: 13px;
  }
  .text{
    display: flex;
    justify-content: center;
  }
  h1{
    color: white;
    font-weight: 700;
  }
  h3{
    color: white;
    font-weight: 700;
   
  }
  .title h1{
    color: #282A35;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif
  }
