.register input{
    box-shadow: 3px 3px;
    font-size: 30px;
    text-align: center;
}

.register-section{
    background-image: url("https://i.ibb.co/Y29c70m/Field.jpg");
        height: 100vh;
      
        /* Make the background image cover the entire container */
        background-size: cover;
      
        /* Center the background image horizontally and vertically */
        background-position: center;
        background-repeat: no-repeat;
}
.register-form{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}