
.heading-academy{
    background: #c2e59c;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #64b3f4, #c2e59c);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #64b3f4, #c2e59c); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
padding: 15px;
border-radius: 20px;
margin-top: 10px;
box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;

}

.heading-academy h1{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.district-cards{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

 
  .district-all-cards{
    display: flex;
    justify-content: center;
  }
  .district-cards img{
    width: 30px;
    height: 25px;
  }
  .district-single{
    margin: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 15px;
  }
  .district-single p{
    color: rgb(5, 5, 5);
    font-size: 10px;
  }
  .text{
    display: flex;
    justify-content: center;
  }
  h1{
    color: white;
    font-weight: 700;
  }
  h3{
    color: white;
    font-weight: 700;
   
  }
  .title h1{
    color: #282A35;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif
  }